export const programs = [
    {
        id: '0001',
        title: 'Basic coding and computer science with Code.org',
        videoSinhala: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        videoEnglish: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        contentSinhala: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        contentEnglish: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        sessionDate: '2021-10-01',
        month: 'October',
    },
    {
        id: '0002',
        title: 'Basic coding and computer A',
        videoSinhala: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        videoEnglish: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        contentSinhala: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        contentEnglish: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        sessionDate: '2021-10-01',
        month: 'November',
    },
    {
        id: '0003',
        title: 'Basic coding and computer B',
        videoSinhala: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        videoEnglish: 'https://www.youtube.com/embed/DjfN0Bp90ZA?si=KUN2so_0EhMLAvA',
        contentSinhala: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        contentEnglish: 'https://stemup-my.sharepoint.com/:b:/g/personal/v-isuru_stemup_lk/EbZxzEbJJ7hGlqCWfcanfjcBQvuRcVbaohylTJa8EUZpiQ?e=kZkwgl',
        sessionDate: '2021-10-01',
        month: 'December',
    },
]