export const codeclubs = [
    {
        id: '0001',
        name: 'Colombo Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.815850220447!2d79.85649251532593!3d6.912609020433907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2596ec416915f%3A0x665df8723d642688!2sColombo%20Public%20Library!5e0!3m2!1sen!2slk!4v1675015472879!5m2!1sen!2slk',
        district: 'Colombo'
    },
    {
        id: '0002',
        name: 'SLT Training Center, Moratuwa',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.013187412457!2d79.89361141532586!3d6.76824542214606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae245a1ec4353b7%3A0xf69687f96b98e210!2sTelecom%20Training%20Centre%20Moratuwa!5e0!3m2!1sen!2slk!4v1681179348760!5m2!1sen!2slk',
        district: 'Colombo'
    },
    {
        id: '0003',
        name: 'SLT Training Center, Peradeniya',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.787476764276!2d80.59388261525649!3d7.265010316113068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae369ad67ae5d53%3A0x17763a919ee92320!2sSri%20Lanka%20Telecom%20Training%20Centre%2C%20Kandy!5e0!3m2!1sen!2slk!4v1680798001353!5m2!1sen!2slk',
        district: 'Kandy'
    },
    {
        id: '0004',
        name: 'Doluwa Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11100.285632491332!2d80.60556498133299!3d7.180295936269036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae36fc190f3dde5%3A0x95fee532d10d8daf!2sDoluwa%20Public%20Library!5e0!3m2!1sen!2slk!4v1680797878181!5m2!1sen!2slk',
        district: 'Kandy'
    },
    {
        id: '0005',
        name: 'Kirimatiyana Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d723324.3615334416!2d80.0178344170709!3d7.831531084217991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c2f0301e1bbd%3A0x8bab9753f979a4b7!2zS2lyaW1hdGl5YW5hIFB1YmxpYyBMaWJyYXJ5IOC2muC3kuC2u-C3kuC2uOC3kOC2p-C3kuC2uuC3j-C2sSDgtrTgt5zgtq_gt5Qg4La04LeU4LeD4LeK4Lat4Laa4LeP4La94La6!5e0!3m2!1sen!2slk!4v1680798664735!5m2!1sen!2slk',
        district: 'Colombo'
    },
    {
        id: '0006',
        name: 'Batticaloa',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d67800.60124632836!2d81.67305358134772!3d7.708531844201497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afacd5056dc1a7f%3A0x11979bb4f51a3e9a!2sPublic%20Library%20-%20Batticaloa!5e0!3m2!1sen!2slk!4v1680798756875!5m2!1sen!2slk',
        district: 'Batticaloa'
    },
    {
        id: '0007',
        name: 'Hanguranketha Public Library',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.787476764276!2d80.59388261525649!3d7.265010316113068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae369ad67ae5d53%3A0x17763a919ee92320!2sSri%20Lanka%20Telecom%20Training%20Centre%2C%20Kandy!5e0!3m2!1sen!2slk!4v1680798001353!5m2!1sen!2slk',
        district: 'Nuwaraeliya'
    },
    {
        id: '0008',
        name: 'SLT Training Center, Welisara',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.9123082020114!2d79.89805731532608!3d7.01959361914327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f7991e5d99cd%3A0x92464ead6e8776d0!2sSri%20Lanka%20Telecom%20Training%20Centre!5e0!3m2!1sen!2slk!4v1681179745688!5m2!1sen!2slk',
        district: 'Gampaha'
    }
]
