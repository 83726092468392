import React from 'react'

import { FacebookFilled, InstagramFilled, LinkedinFilled, TwitterCircleFilled, YoutubeFilled } from '@ant-design/icons'

import './footer.scss'
import { useEffect } from 'react';

const Footer = () => {
    useEffect(() => {
        if (window.location.hash === '#about-us') {
            const destinationElement = document.getElementById('about-us');
            if (destinationElement) {
                destinationElement.scrollIntoView();
            }
        }
    }, []);

    return (
        <div className='footer'>
            <div className='footer__container'>
                <div className='footer-item logo'>
                    <div className='footer-logo'>
                        {/* <img src={require('../Assets/Logo/Lumosys-logo.png')} alt='logo' /> */}
                    </div>
                    <div className='footer-social'>
                        <h3>Follow Us</h3>
                        <div className='footer-social__icons'>
                            <a href='/' target='_blank' rel='noopener noreferrer'>
                                <FacebookFilled />
                            </a>

                            <a href='/' target='_blank' rel='noopener noreferrer'>
                                <InstagramFilled />
                            </a>

                            <a href='/' target='_blank' rel='noopener noreferrer'>
                                <LinkedinFilled />
                            </a>

                            <a href='/ ' target='_blank' rel='noopener noreferrer'>
                                <YoutubeFilled />
                            </a>

                            <a href='/ ' target='_blank' rel='noopener noreferrer'>
                                <TwitterCircleFilled />
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div className='footer-terms'>
                <p>
                    © 2024 STEMUP Educational Foundation. All Rights Reserved
                </p>

                <p>
                    Designed by STEMUP | <a href='/'>Privacy Policy</a>
                </p>
            </div>
        </div>
    )
}

export default Footer