import { Button, Form, Select, Checkbox, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { codeclubs } from '../../../Files/centers'
import './styles.scss'
import { db } from '../../../firebase'
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const Attendance = () => {
    const [loading, setLoading] = useState(false)
    const [students, setStudents] = useState([])
    const [filteredStudents, setFilteredStudents] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")

    const handleGetStudents = async (values) => {
        setLoading(true)
        try {
            let newStudents = []
            const studentsSnapshot = await getDocs(collection(db, 'registrations'))

            studentsSnapshot?.forEach(doc => {
                const student = doc.data()
                if (student.center === values.center) {
                    student.id = doc.id; // Add document ID to the student object
                    newStudents.push(student)
                }
            })

            setStudents(newStudents)
            setFilteredStudents(newStudents)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleMonthChange = (value) => {
        setSelectedMonth(value)
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchTerm(value)
        const filtered = students.filter(student =>
            student.childName.toLowerCase().includes(value)
        )
        setFilteredStudents(filtered)
    }

    const handleToggleAttendance = async (studentId) => {
        if (!selectedMonth) {
            alert("Please select a month to toggle attendance.")
            return
        }

        try {
            const studentRef = doc(db, 'registrations', studentId)
            const student = students.find(student => student.id === studentId)
            let updatedAttendance

            if (student.attendance.includes(selectedMonth)) {
                // Remove the month from the attendance array
                updatedAttendance = student.attendance.filter(month => month !== selectedMonth)
            } else {
                // Add the month to the attendance array
                updatedAttendance = [...student.attendance, selectedMonth]
            }

            // Update the attendance array in Firestore
            await updateDoc(studentRef, {
                attendance: updatedAttendance
            })

            // Update the local state
            setStudents(prevStudents =>
                prevStudents.map(student =>
                    student.id === studentId
                        ? { ...student, attendance: updatedAttendance }
                        : student
                )
            )

            // Update the filtered list as well
            setFilteredStudents(prevStudents =>
                prevStudents.map(student =>
                    student.id === studentId
                        ? { ...student, attendance: updatedAttendance }
                        : student
                )
            )
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='attendance'>
            <div className='header'>
                <h1>Attendance</h1>

                <Form
                    layout='inline'
                    onFinish={handleGetStudents}
                >
                    <Form.Item
                        label='Select a center'
                        name='center'
                    >
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a center"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {codeclubs.map((center, index) => (
                                <Select.Option key={index} value={center.id}>
                                    {center.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            Search
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className='search'>
                <Input
                    placeholder="Search student by name"
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: 300 }}
                />

                <Select
                    style={{ width: 300 }}
                    placeholder="Select a month to toggle attendance"
                    onChange={handleMonthChange}
                >
                    {months.map((month, index) => (
                        <Select.Option key={index} value={month}>
                            {month}
                        </Select.Option>
                    ))}
                </Select>
            </div>

            <div className='students'>
                {filteredStudents.map((student, index) => (
                    <div key={index} className='student'>
                        <div className='info'>
                            <h2>{student.childName}</h2>
                            <p><strong>Center: </strong>{
                                codeclubs.find(center => center.id === student.center)?.name
                            }</p>
                        </div>
                        <div className='data'>
                            <div className='boxes'>
                                {months.map((month, idx) => (
                                    <Checkbox
                                        key={idx}
                                        checked={student.attendance.includes(month)}
                                    >
                                        {month}
                                    </Checkbox>
                                ))}
                            </div>
                            <Button
                                type='primary'
                                onClick={() => handleToggleAttendance(student.id)}
                                disabled={!selectedMonth}
                            >
                                {student.attendance.includes(selectedMonth) ? 'Undo Attendance' : 'Mark as Attended'}
                            </Button>
                        </div>
                        <p><strong>
                            Total Attendance: {student.attendance.length} / {months.length}
                        </strong>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Attendance
