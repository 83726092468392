import React, { useEffect, useState } from 'react'

import { programs } from '../../../Files/programs'

import './styles.scss'
import { Select } from 'antd'

const months = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const Training = () => {
    const [programsFiltered, setProgramsFiltered] = useState([])

    useEffect(() => {
        setProgramsFiltered(programs)
    }, [])

    return (
        <div className='training'>
            <div className='header'>
                <h1>Training</h1>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a month"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                        if (value === 'All') {
                            setProgramsFiltered(programs)
                        } else {
                            setProgramsFiltered(programs.filter(program => program.month === value))
                        }
                    }}
                >
                    {months.map((month, index) => (
                        <Select.Option key={index} value={month}>
                            {month}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className='trins'>
                {programsFiltered.map((program, index) => (
                    <div key={index} className='program'>
                        <h2>{program.title}</h2>
                        <h3>Month: {program.month}</h3>
                        <div className='description'>
                            <p><strong>ID: </strong> {program.id}</p>
                            <p><strong>Session Date: </strong>{program.sessionDate}</p>
                        </div>
                        <div className='contents'>
                            <div className='sinhala'>
                                <h3>Sinhala</h3>
                                <iframe width="400" height="220" src={program.videoSinhala} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                <div className='download'>
                                    <a href={program.contentSinhala} download target='_blank' rel='noreferrer'>
                                        Download content
                                    </a>
                                </div>
                            </div>
                            <div className='english'>
                                <h3>English</h3>
                                <iframe width="400" height="220" src={program.videoEnglish} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                <div className='download'>
                                    <a href={program.contentEnglish} download target='_blank' rel='noreferrer'>
                                        Download content
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Training