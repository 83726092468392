const loginsData = [
    {
        email: 'emperorisuruariyarathna@gmail.com',
        password: '1234'
    },
    {
        email: 'isuruariyarathna2k00@gmail.com',
        password: '200022601753',
        role: 'super-admin'
    }
]

export default loginsData;