import React, { useEffect, useState } from 'react';

import Navigator from "./Common/nav";
import Footer from "./Common/footer";
import Routes from "./Routes";

import './App.scss';
import { FloatButton } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';

function App() {
  const [showGoTop, setShowGoTop] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, []);

  // when react the bottom of the page is reached, show the chat button
  window.onscroll = function () {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
  };

  return (
    <div className="App">
      <Navigator />
      <Routes />
      <Footer />

      <FloatButton
        style={{
          bottom: isSmallScreen ? 90 : 120,
          right: isSmallScreen ? 26 : 58,
          display: showGoTop ? 'block' : 'none'
        }}
        icon={<ArrowUpOutlined />}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }}
      />
    </div>
  );
}

export default App;
