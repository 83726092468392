import React, { useState, useEffect } from 'react'

import { PhoneFilled, MailFilled, QuestionCircleOutlined, WhatsAppOutlined, SmileOutlined, MailOutlined, EnvironmentFilled } from '@ant-design/icons';

import { Divider, Spin, notification } from 'antd';



import emailjs from '@emailjs/browser';

import './style.scss'

const SERVICE_ID = "service_kvoadka";
const TEMPLATE_ID = "template_xea2f98";
const PUBLIC_KEY = "OgBxRKmfeOxTA-9N9";


const ContactUs = () => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navhelp");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        if (e.target.user_name.value === '' || e.target.email_id.value === '' || e.target.message.value === '') {
            openErrorNotification()
        } else {
            setLoading(true)
            emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
                .then((result) => {
                    openNotification()
                    setLoading(false)
                }, (error) => {
                    openErrorNotification()
                    setLoading(false)
                });
        }
    }

    const openNotification = () => {
        api.open({
            message: 'Message Sent Successfully',
            description: "We'll get back to you as soon as possible.",
            icon: (
                <SmileOutlined
                    style={{
                        color: '#108ee9',
                    }}
                />
            ),
        });
    };

    const openErrorNotification = () => {
        api.open({
            message: "Something went wrong!",
            description: "Please try again later.",
            icon: (
                <QuestionCircleOutlined
                    style={{
                        color: '#ff0000',
                    }}
                />
            ),
        });
    };

    return (
        <div className='contact-us-page'>
            {contextHolder}
            <h3 className='title-top'>
                Contact Us
            </h3>
            <h2 className='title'>
                We are here for you
            </h2>

            <div className='contact-info'>
                <a href='mailto:leaders@stemup.lk'>
                    <MailFilled />
                    <p>leaders@stemup.lk</p>
                </a>
                <Divider />
                <a href='tel:+94721811811'>
                    <PhoneFilled />
                    <p>+94 72 1811 811</p>
                </a>
                <Divider />
                <a href='/'>
                    <EnvironmentFilled />
                    <p>No:67/2, Wewalduwa,Kelaniya, 11600.</p>
                </a>
            </div>
        </div>
    )
}

export default ContactUs