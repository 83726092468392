import React, { useEffect, useState } from 'react'

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Reveal
import AOS from 'aos';
import 'aos/dist/aos.css';

import './style.scss'
import './slider.css'

import data from "./Assets/Centers.json";
import { Button, Image } from 'antd';

import image1 from './Assets/CodeDojoImages/1.jpeg'
import image2 from './Assets/CodeDojoImages/2.jpeg'
import image3 from './Assets/CodeDojoImages/3.jpeg'
import image4 from './Assets/CodeDojoImages/4.jpeg'
import image5 from './Assets/CodeDojoImages/5.jpeg'
import image6 from './Assets/CodeDojoImages/6.jpeg'
import image7 from './Assets/CodeDojoImages/7.jpeg'
import image8 from './Assets/CodeDojoImages/8.jpeg'
import image9 from './Assets/CodeDojoImages/9.jpeg'
import image10 from './Assets/CodeDojoImages/10.jpeg'
import { SearchOutlined } from '@ant-design/icons';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 770 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 770, min: 0 },
        items: 1,
    },
};

const reviewresponsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1450 },
        items: 1,
    },
    desktopMini: {
        breakpoint: { max: 1450, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
}

AOS.init();
AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,

    offset: 120,
    delay: 0,
    duration: 800,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom',
});

const Home = () => {
    const [mapSrc, setMapSrc] = useState("");
    const [centers, setCenters] = useState([]);
    const [maps, setMaps] = useState([]);
    const [selectedMap, setSelectedMap] = useState("");
    const [searchCenters, setSearchCenters] = useState(false);

    useEffect(() => {
        let tempCenters = [];
        let tempMaps = [];
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].centers.length; j++) {
                tempCenters.push(data[i].centers[j].center);
                tempMaps.push(data[i].centers[j].mapSrc);
            }
        }
        setCenters(tempCenters);
        setMaps(tempMaps);
        setSelectedMap(
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2023616.6687927146!2d79.3868294156721!3d7.855627643218986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c54724933453%3A0xd31d9534b313889c!2sSTEMUP%20Educational%20Foundation!5e0!3m2!1sen!2slk!4v1685297742450!5m2!1sen!2slk"
        );
    }, []);

    // Active link
    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navhome");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    const handleSearchClick = () => {
        let centers = document.querySelector(".centers");
        setSearchCenters(!searchCenters);
        if (!searchCenters) {
            centers.style.display = "block";
        } else {
            centers.style.display = "none";
        }
    };

    const handleSelectCanter = (e) => {
        const centerNames = document.querySelectorAll(".centerName .center");
        for (let i = 0; i < centerNames.length; i++) {
            // remove active class to the selected center
            centerNames[i].classList.remove("active");
        }
        e.target.parentNode.classList.add("active");

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].centers.length; j++) {
                if (data[i].centers[j].center === e.target.innerText) {
                    setMapSrc(data[i].centers[j].mapSrc);
                    setSelectedMap(data[i].centers[j].mapSrc);
                }
            }
        }
        // if screen size is less than 768px then close the search centers
        if (window.innerWidth < 770) {
            setSearchCenters(false);
            let centers = document.querySelector(".centers");
            centers.style.display = "none";
        }
    };

    const isOdd = (num) => {
        return num % 2;
    };

    return (
        <div className='home-page'>
            {/* 
                /// Hero Section
                ______________________________________________////
                ///
             */}
            <div className='hero'>
                <h1 data-aos="fade-up">
                    <span>Code Clubs</span>
                    <br />
                    Sri Lanka
                </h1>
                <h4 data-aos="fade-up">
                    A Dojo is a free, volunteer-led, community-based programming club for young people. Anyone aged 10 to 17 can visit a Dojo and learn to code, build a website, or create an app or game. Dojos are a space for young people to explore technology in an informal, creative, safe and social environment.
                </h4>
                <div className='hero-buttons' >
                    <a href='/products'> Find a Code Club </a>
                    <a className='border-btn' href='/services'> Join Now </a>
                </div>
                <div className='horo_img'>
                    <img src={require('./Assets/coder.png')} alt='hero' />
                </div>
            </div>

            {
                /* 
                    /// About Section
                    ______________________________________________////
                    ///
                */
            }
            <div className='about' id='about-us'>
                <div className='about-left'>
                    <h3 className='title-top' data-aos="fade-up">What is </h3>
                    <h2 data-aos="fade-up" className='title'>
                        Code Clubs Sri Lanka
                    </h2>

                    <p className='para' data-aos="fade-up">
                        CoderDojo Sri Lanka, managed by STEMUP Educational Foundation, is the Sri Lankan network of "CoderDojo." As the Official Country Partner of Raspberry Pi Foundation and CoderDojo Foundation, it has created 30+ rural IT Centers as supportive Dojos, offering coding education. STEMUP Volunteers also lead Dojos in libraries and SLT-Mobitel Training Centers.
                        <br />
                        <br />
                        CoderDojo Sri Lanka promotes digital literacy and empowers youth with future-ready skills. Dojos provide an inclusive platform for children to learn coding, fostering creativity and problem-solving abilities.

                        CoderDojo Sri Lanka shapes an inclusive, technologically proficient society by promoting digital literacy and empowering youth. Its network of Dojos and volunteers paves the way for young people to thrive in the digital age.
                    </p>
                </div>
                <div className='about-right' data-aos="zoom-in">
                    <img src={require('./Assets/joinwithus.png')} alt='Services' />
                </div>
            </div>

            {/* 
            /// Services Section
            ______________________________________________////
            ///
            */}
            <div className='services'>
                <div className='services-inner' data-aos="fade-up">
                    <h2 className='title'>
                        Our Programs
                    </h2>
                </div>

                <div className='section-five-cards' data-aos="zoom-in">
                    <div className="Slider">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            showDots={true}
                            autoPlaySpeed={3000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[
                                "tablet",
                                "mobile",
                                "desktop",
                                "superLargeDesktop",
                                "desktopMini",
                            ]}
                            dotListClass="custom-dot-list-style"
                            activeDotClass="custom-dot-active"
                        >

                            <div className="card">
                                <h4>
                                    Basic coding and computer science with Code.org
                                </h4>
                                <div className='card-content'>
                                    <p>
                                        Basic coding and computer science: Learn coding and computer science
                                        essentials with Code.org.
                                    </p>
                                </div>
                            </div>

                            <div className="card">
                                <h4>
                                    Programming Fundamentals with Scratch
                                </h4>
                                <div className='card-content'>
                                    <p>
                                        Programming Fundamentals: Develop coding skills with Scratch, a
                                        visual programming language.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <h4>
                                    Get started with Micro:bit
                                </h4>
                                <div className='card-content'>
                                    <p>
                                        Get started with Micro:bit: Explore the possibilities of Micro:bit
                                        and create projects.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <h4>
                                    Get started with Arduino
                                </h4>
                                <div className='card-content'>
                                    <p>
                                        Get started with Arduino: Learn to build and program electronics
                                        projects with Arduino.
                                    </p>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>


            {/* 
            /// Products Section
            ______________________________________________////
            ///
            */}

            <div className='home-products'>
                <div className='home-products-top'>
                    <h2 className='title'>
                        Find Your
                        <br /> Nearest Code Club
                    </h2>
                </div>
                <div className='home-products-bottom' data-aos="zoom-in">
                    <div className="mapComp">
                        <div className="map">
                            <iframe
                                title="map"
                                src={selectedMap}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <div className="searchCenters">
                                <Button icon={<SearchOutlined style={{
                                    color: "white",
                                    fontSize: "30px",
                                }} />} onClick={handleSearchClick}>
                                </Button>
                            </div>
                        </div>
                        <div className="centers">
                            <h2>
                                Select Your Center
                            </h2>
                            <div className="centerName">
                                {centers.map((center, index) => (
                                    <div
                                        key={index}
                                        className={`center ${isOdd(index) ? "odd" : "even"}`}
                                        onClick={handleSelectCanter}
                                    >
                                        <span>{center}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 
            /// Reviews Section
            ______________________________________________////
            ///
            */}

            <div className='home-reviews'>
                <div className='home-reviews-up' data-aos="fade-up">
                    <h3 className='title-top'>
                        Get in Touch
                    </h3>
                    <h2 className='title'>
                        Latest Announcements
                    </h2>
                </div>

                <div className='home-reviews-bottum'>
                    <div className="review-slider" data-aos="zoom-in">
                        <Carousel
                            responsive={reviewresponsive}
                            infinite={true}
                            autoPlay={true}
                            showDots={true}
                            autoPlaySpeed={3000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[
                                "tablet",
                                "mobile",
                                "desktop",
                                "superLargeDesktop",
                                "desktopMini",
                            ]}
                            dotListClass="custom-dot-list-style"
                            activeDotClass="custom-dot-active"
                        >

                            <div className="card">
                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>

            <div className='home-products'>
                <div className='home-products-top'>
                    <h2 className='title'>
                        Memory Lane
                    </h2>
                </div>
                <div className='home-products-bottom' data-aos="zoom-in">
                    <div className="MemoryCorner">
                        <Image.PreviewGroup
                            items={[
                                image1,
                                image2,
                                image3,
                                image4,
                                image5,
                                image6,
                                image7,
                                image8,
                                image9,
                                image10
                            ]}
                        >
                            <div className="imgageContainer">
                                <Image
                                    id="img1"
                                    src={image1}
                                />
                                <Image src={image2} />
                                <Image src={image3} />
                                <Image src={image4} />
                            </div>
                        </Image.PreviewGroup>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default Home