import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home";
import ContactUs from "./Pages/Contact";
import Register from "./Pages/Regsiter";
import Signin from "./Pages/Signin";
import Admin from "./Pages/Admin";
import Programs from "./Pages/Porgrams";

const RoutesJs = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/help" element={<ContactUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesJs;
